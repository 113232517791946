import { routeConfig } from "../router";
import { QueryClientProvider } from "@tanstack/react-query";

require("@aspen/theme/app.less");

import type { AppProps } from "next/app";
import React, { useEffect } from "react";
import {
  checkRouterAuth,
  checkSupportWebp,
  reportEvent,
  i18nUtil,
  getQueryValue,
  ONLY_MOBILE,
  cookieUtil,
  getLocalStorageTokenKey,
  apikeyGa,
  apikeyGtm,
  isDev,
  LANGUAGE,
  FORMATWEBP,
  ZH_CN,
  DAYJSEN,
  DAYJSZH,
  USER,
  IS_AUTHENTICATED,
  USER_AUTH,
  GA_EVENT_NAME,
  EN,
  handleRouteChangeStart,
  isClient,
  PARTNER_PATH
} from "@aspen/libs";
import type { NextRouter } from "next/router";
import { useRouter } from "next/router";
import { ConfigProvider } from "antd";
import enUS from "antd/lib/locale/en_US";
import zhCN from "antd/lib/locale/zh_CN";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import { PersistGate } from "redux-persist/integration/react";
import { WithLogging, getPageHeader } from "@aspen/ui";
import {queryClient, QueryClientContextClient, store} from "@aspen/store";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import Head from "next/head";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
import type { InspectParams } from "react-dev-inspector";
import { Inspector } from "react-dev-inspector";
import type { NextComponentType, NextPageContext } from "next/types";
import { ThemeProvider } from "next-themes";

import WithLayout from "../components/WithLayout";

require("@aspen/theme/iconfont/iconfont.css");

const persistor = persistStore(store, {}, () => {
  persistor.persist();
});

type IProps = {
  Component: NextComponentType<NextPageContext, unknown>; // any 不修改
  pageProps: unknown; // 不修改
} & AppProps;

function MyApp(props: IProps) {
  const router: NextRouter = useRouter();
  // 勿删
  // @ts-ignore
  i18nUtil.updateLocale(router.locale);

  const { Component, pageProps } = props;

  pageProps.locale = router.locale;
  pageProps.locales = router.locales;
  pageProps.route = router.route;

  /**
   * 修复控制台：Warning: useLayoutEffect does nothing on the server, because its effect cannot be encoded into the server renderer's output format. This will lead to a mismatch between the initial, non-hydrated UI and the intended UI. To avoid this, useLayoutEffect should only be used in components that render exclusively on the client. See https://reactjs.org/link/uselayouteffect-ssr for common fixes.
   * https://usehooks-ts.com/react-hook/use-isomorphic-layout-effect
   */
  if (!process.browser) React.useLayoutEffect = React.useEffect;

  useEffect(() => {
    if (!isClient) return;
    localStorage.setItem(LANGUAGE, router.locale ?? EN);
    // 判断浏览器是否支持webp格式的图片
    checkSupportWebp("lossy", (result) => {
      localStorage.setItem(FORMATWEBP, result ? "" : "format,png");
    });

    // app嵌入时 设置为登陆状态 储存jwtToken 并且请求获取kycInfo
    const jwtToken = getQueryValue("jwtToken");
    const user = decodeURIComponent(getQueryValue("user"));
    // @ts-ignore
    if (ONLY_MOBILE && jwtToken && user) {
      cookieUtil.set(getLocalStorageTokenKey(location.host), jwtToken);
      localStorage.setItem(getLocalStorageTokenKey(location.origin), jwtToken ?? "");
      localStorage.setItem(USER, user);
      localStorage.setItem(IS_AUTHENTICATED, "true");
      localStorage.setItem(USER_AUTH, "customer");
    }

    // 刷新页面时 增加埋点  TODO:关闭会误报
    window.onbeforeunload = () => {
      reportEvent({ moduleName: GA_EVENT_NAME.common.pageRefresh });
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  // https://github.com/vercel/next.js/blob/canary/examples/with-google-analytics/pages/_app.js
  useEffect(() => {
    if (apikeyGa) ReactGA.initialize(apikeyGa);
    if (apikeyGtm) TagManager.initialize({ gtmId: apikeyGtm });
    // 点击跳转路由的统一上报事件，不用单独上报
    // router.events.on("routeChangeComplete", handleRouteChange);
    router.events.on("routeChangeStart", handleRouteChangeStart);

    return () => {
      // router.events.off("routeChangeComplete", handleRouteChange);
      router.events.off("routeChangeStart", handleRouteChangeStart);
    };
  }, [router.events]);

  useEffect(() => {
    if (!isClient) {
      return;
    }
    checkRouterAuth(routeConfig, router, PARTNER_PATH.DASHBOARD);
  }, [router.asPath]);

  const defaultTheme = "mode-dark";
  const pageLayout: JSX.Element = (
      <QueryClientProvider client={queryClient}>
        <QueryClientContextClient.Provider value={{ queryClient }}>
          <WithLayout router={router} routeConfig={routeConfig}>
            <ThemeProvider defaultTheme={defaultTheme}>
              <Component {...pageProps} />
            </ThemeProvider>
          </WithLayout>
        </QueryClientContextClient.Provider>
      </QueryClientProvider>
  );

  // 处理antd moment.js替换dayjs与国际化
  dayjs.extend(weekday);
  dayjs.extend(localeData);
  if (router.locale === ZH_CN) {
    dayjs.locale(DAYJSZH);
  } else {
    dayjs.locale(DAYJSEN);
  }
  const locale = router.locale === ZH_CN ? zhCN : enUS;

  return isDev ? (
    <>
      <Inspector
        disableLaunchEditor={!isDev}
        // props see docs:
        // https://github.com/zthxxx/react-dev-inspector#inspector-component-props
        keys={["control", "shift", "command", "c"]}
        onClickElement={({ codeInfo }: InspectParams) => {
          let absolutePath = "/Users/elaine/Desktop/monorepo_aspen_web"; // 你的项目目录地址
          if (codeInfo?.absolutePath) absolutePath = codeInfo?.absolutePath;
          const { lineNumber, columnNumber, relativePath } = codeInfo || {};
          // you can change the url protocol if you are using in Web IDE
          window.open(
            `vscode://file/${absolutePath}/${relativePath}:${lineNumber}:${columnNumber}`
          );
        }}>
        <Head>{getPageHeader({ routeConfig, pathname: router.route })}</Head>
        <WithLogging>
          <Provider store={store}>
            <ConfigProvider locale={locale}>
              <PersistGate loading={null} persistor={persistor} />
              {pageLayout}
            </ConfigProvider>
          </Provider>
        </WithLogging>
      </Inspector>
    </>
  ) : (
    <>
      <Head>{getPageHeader({ routeConfig, pathname: router.route })}</Head>
      <WithLogging>
        <Provider store={store}>
          <ConfigProvider locale={locale}>
            <PersistGate loading={null} persistor={persistor} />
            {pageLayout}
          </ConfigProvider>
        </Provider>
      </WithLogging>
    </>
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://github.com/facebook/create-react-app/blob/main/packages/cra-template/template/README.md
// serviceWorker.unregister();

export default MyApp;
